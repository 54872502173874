<template>
	<section class="container-fluid py-5 d-flex flex-column align-items-center">
		<h1>Rapporter</h1>
		<p class="text-center mb-4">Download detaljerede rapporter med blot ét klik - det kan tage op til 2-3 minutter</p>
		<p class="text-center mb-4" v-if="!usersIsReady">Indlæser... - Afvent venligst</p>

		<div class="d-flex justify-content-center w-100 flex-wrap">
			<div class="rapport-section m-3">
				<h1 class="mb-4 text-center">Medarbejder rapport</h1>
				<BaseActionButton type="button" class="btn btn-primary mb-4" v-on:on-click="generateRapport" :disabled="isLoading || !usersIsReady">
					<span v-if="isLoading">
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						Indlæser...
					</span>
					<span v-else> Lav medarbejdersrapport </span>
				</BaseActionButton>
				<xlsx-workbook v-if="rapportIsReady" class="mb-4">
					<xlsx-sheet :collection="sheet.data" v-for="sheet in getExcelFormattedData" :key="sheet.name" :sheet-name="sheet.name" />
					<xlsx-download :filename="getFileName">
						<b-button class="btn btn-success">Download rapport - {{ getFileName }}</b-button>
					</xlsx-download>
				</xlsx-workbook>
			</div>
			<div class="rapport-section m-3">
				<h1 class="mb-4 text-center">Virksomhedsrapport</h1>
				<BaseActionButton type="button" class="btn btn-primary mb-4" v-on:on-click="generateCompanyRapport" :disabled="companyIsLoading || !usersIsReady">
					<span v-if="companyIsLoading">
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						Indlæser...
					</span>
					<span v-else> Lav virksomhedsrapport </span>
				</BaseActionButton>
				<xlsx-workbook v-if="companyRapportIsReady" class="mb-4">
					<xlsx-sheet :collection="sheet.data" v-for="sheet in getExcelCompanyData" :key="sheet.name" :sheet-name="sheet.name" />
					<xlsx-download :filename="getFileName">
						<b-button class="btn btn-success">Download rapport - {{ getCompanyFileName }}</b-button>
					</xlsx-download>
				</xlsx-workbook>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import config from '../../../../../conf.json'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'

export default {
	name: 'Rapport',
	components: {
		XlsxWorkbook,
		XlsxSheet,
		XlsxDownload,
	},
	data() {
		return {
			isReady: false,
			rapportIsReady: false,
			companyRapportIsReady: false,
			companyIsLoading: false,
			csvArray: [],
			companyCsvArray: [],
			isLoading: false,
			transferArray: [],
			custodianValueArray: [],
		}
	},
	created() {
		this.$emit('updateLoader')
	},
	methods: {
		getUserFromUid(uid) {
			return this.$store.getters['user/users'].find((user) => user.id === uid)
		},
		async generateRapport() {
			this.isLoading = true
			try {
				let idToken = await this.$store.getters['auth/idToken']

				var options = {
					url: config.firebaseConfig.apiURL + '/integration/csv',
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
				var result = await axios(options)
				this.isLoading = false

				this.csvArray = result.data.filter((el) => el != null)

				this.csvArray.forEach((element) => {
					element['Pensionstart'] = element['Pensionstart'] ? new Date(element['Pensionstart']).toLocaleDateString('da-DK') : ''
					element['Sundhedsforsikring startdato'] = element['Sundhedsforsikring startdato']
						? new Date(element['Sundhedsforsikring startdato']).toLocaleDateString('da-DK')
						: ''
					element['Fratrædelsesdato'] = element['Fratrædelsesdato'] ? new Date(element['Fratrædelsesdato']).toLocaleDateString('da-DK') : ''
					element['Ansættelsedato'] = element['Ansættelsedato'] ? new Date(element['Ansættelsedato']).toLocaleDateString('da-DK') : ''

					this.transferArray.push(element['Overførsel'])
					this.custodianValueArray.push(element['Depotværdi'])
					delete element['Overførsel']
					delete element['Depotværdi']
				})

				this.transferArray = this.transferArray
					.flat()
					.map((el) => ({ ...el, Dato: el['Dato'] ? new Date(el['Dato']).toLocaleDateString('da-DK') : '' }))
				this.custodianValueArray = this.custodianValueArray
					.flat()
					.map((el) => ({ ...el, Dato: el['Dato'] ? new Date(el['Dato']).toLocaleDateString('da-DK') : '' }))

				this.rapportIsReady = true
			} catch (error) {
				this.isLoading = false
				this.$toast.error('Der skete en fejl ved generering af rapporten')
			}
		},
		async generateCompanyRapport() {
			this.companyIsLoading = true
			try {
				let idToken = await this.$store.getters['auth/idToken']

				var options = {
					url: config.firebaseConfig.apiURL + '/company',
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
				var result = await axios(options)
				this.companyIsLoading = false

				var data = result.data.filter((el) => el != null)
				this.companyCsvArray = data.map((element) => {
					var advisor1 = this.getUserFromUid(element?.companyAdvisors?.primaryAdvisor)
					var advisor2 = this.getUserFromUid(element?.companyAdvisors?.secondaryAdvisor)
					var companyAdmin = element?.companyAdmins.map((el) => this.getUserFromUid(el))
					return {
						Virksomhedsnavn: element.companyInfo?.name || '',
						CVR: element.companyInfo?.cvr || '',
						Adresse: element.companyInfo?.address?.address || '',
						By: element.companyInfo?.address?.city || '',
						Postnummer: element.companyInfo?.address?.zip || '',
						Telefon: element.companyInfo?.phone || '',
						KontaktpersonEmail: element.companyInfo?.contactPersonEmail || '',
						KontaktpersonNavn: element.companyInfo?.contactPersonFullName || '',
						Creditro: element.companyInfo?.creditro ? 'Ja' : 'Nej',
						Markedsføringssamtykke: element.marketingConsent ? 'Ja' : 'Nej',
						Startdato: element.companyInfo?.startDate ? new Date(element.companyInfo?.startDate).toLocaleDateString('da-DK') : '',
						Slutdato: element.companyInfo?.endDate ? new Date(element.companyInfo?.endDate).toLocaleDateString('da-DK') : '',
						Hovedforfald: this.getMonth(element.companyInfo?.hovedforfald) || '',
						Raadgiver1_navn: advisor1 ? advisor1.fullName : '',
						Raadgiver2_navn: advisor2 ? advisor2.fullName : '',
						Virksomhedsadmin1: companyAdmin[0] ? companyAdmin[0].fullName : '',
						Virksomhedsadmin2: companyAdmin[1] ? companyAdmin[1].fullName : '',
						Virksomhedsadmin3: companyAdmin[2] ? companyAdmin[2].fullName : '',
						Virksomhedsadmin4: companyAdmin[3] ? companyAdmin[3].fullName : '',
						Virksomhedsadmin5: companyAdmin[4] ? companyAdmin[4].fullName : '',
					}
				})
				this.companyRapportIsReady = true
			} catch (error) {
				this.isLoading = false
				console.log(error)
				this.$toast.error('Der skete en fejl ved generering af rapporten')
			}
		},
		getMonth(month) {
			switch (month) {
				case 1:
					return 'Januar'
				case 2:
					return 'Februar'
				case 3:
					return 'Marts'
				case 4:
					return 'April'
				case 5:
					return 'Maj'
				case 6:
					return 'Juni'
				case 7:
					return 'Juli'
				case 8:
					return 'August'
				case 9:
					return 'September'
				case 10:
					return 'Oktober'
				case 11:
					return 'November'
				case 12:
					return 'December'
				default:
					return ''
			}
		},
	},
	computed: {
		getFileName() {
			return `Kundeportal_medarbejderlisten_${this.getTodayDateFormatted}.xlsx`
		},
		getCompanyFileName() {
			return `Kundeportal_virksomhedslisten_${this.getTodayDateFormatted}.xlsx`
		},
		getTodayDateFormatted() {
			let today = new Date()
			let dd = String(today.getDate()).padStart(2, '0')
			let mm = String(today.getMonth() + 1).padStart(2, '0')
			let yyyy = today.getFullYear()

			return `${dd}/${mm}/${yyyy}`
		},
		getExcelFormattedData() {
			return [
				{ name: 'Medarbejderlisten', data: this.csvArray },
				{ name: 'Overførsel', data: this.transferArray },
				{ name: 'Depot værdi', data: this.custodianValueArray },
			]
		},
		getExcelCompanyData() {
			return [{ name: 'Virksomhedsliste', data: this.companyCsvArray }]
		},
		usersIsReady() {
			return this.$store.getters['user/isReady']
		},
	},
}
</script>

<style scoped>
.container-fluid {
	background-color: #f8f9fa;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
	font-size: 2rem;
	color: #343a40;
}

p {
	font-size: 1.2rem;
	color: #6c757d;
}

.btn-primary {
	background-color: #007bff;
	border-color: #007bff;
}

.btn-success {
	background-color: #28a745;
	border-color: #28a745;
}

.rapport-section {
	width: 400px;
	text-align: center;
}
</style>
