<template>
	<section >
		<b-form-textarea
			v-model="inputValue"
			rows="8"
			:disabled="disabled"
			:required="required"
			:state="isRequiredAndFilled"
			:placeholder="placeholder"
		></b-form-textarea>
	</section>
</template>

<script>
import requiredFieldMixin from '../../../mixins/requiredFieldMixin'
export default {
	name: 'InputTextarea',
	props: ['value', 'disabled', 'required'],
	mixins: [requiredFieldMixin],
	data() {
		return {
			placeholder: 'Skriv din kommentar her	',
		}
	},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style></style>
