import Vue from 'vue'
import Router from 'vue-router'
import firebaseApp from '@/plugins/firebase.js'
import { getAuth } from 'firebase/auth'
import config from './../../conf.json'
import store from '@/vuex/store'

Vue.component('loading', { template: '<div>Loading!</div>' })

// Global
const Base = () => import('@/modules/global/views/Base')
const Home = () => import('@/modules/general/home/views/Home')
const Page404 = () => import('@/modules/global/views/Page404')
const PageAccessDenied = () => import('@/modules/global/views/PageAccessDenied')
const Rapport = () => import('@/modules/general/rapport/views/Rapport')

// LoginRouter
import LoginRouter from '@/modules/general/login/router/router'

// SignUpRouter
import SignUpRouter from '@/modules/general/signup/router/router'

// CompanyRouter
import CompanyRouter from '@/modules/general/company/router/router'

// CompanyRouter
import ResignedCompanyRouter from '@/modules/general/resignedCompany/router/router'

// EmployeeRouter
import EmployeeRouter from '@/modules/general/employee/router/router'

// SupplierRouter
import SupplierRouter from '@/modules/general/supplier/router/router'

// Profile
import User from '@/modules/general/user/router/router'

import i18n from '@/plugins/i18n'

Vue.use(Router)

var router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			redirect: '/login',
			component: Base,
			children: [
				{
					path: '/home',
					name: 'Home',
					component: Home,
					meta: {
						requiresLoggedIn: true,
						title: i18n.t('browserTitle.home'),
					},
				},
				{
					path: '/404',
					name: 'Page404',
					component: Page404,
					meta: {
						title: i18n.t('browserTitle.404'),
					},
				},
				{
					path: '/accessdenied',
					name: 'AccessDenied',
					component: PageAccessDenied,
					meta: {
						requiresLoggedIn: true,
						title: i18n.t('browserTitle.accessdenied'),
					},
				},
				...LoginRouter,
				...SignUpRouter,
				...CompanyRouter,
				...ResignedCompanyRouter,
				...EmployeeRouter,
				...SupplierRouter,
				...User,
				{
					path: '/rapport',
					name: 'Rapport',
					component: Rapport,
					meta: {
						requiresLoggedIn: true,
						action: 'read',
						resource: 'Report',
						title: 'Rapport',
					},
				},
			],
		},
	],
})

router.beforeEach(async (to, from, next) => {
	document.title = to.meta.title + ' | ' + config.customization.footerName
	let requiresNotLoggedIn = to.matched.some((record) => record.meta.requiresNotLoggedIn)
	let requiresLoggedIn = to.matched.some((record) => record.meta.requiresLoggedIn)
	var user = store.getters['auth/user']
	var ability = store.getters['auth/ability']

	let canNavigate = true

	if (to.meta.action && to.meta.resource) {
		canNavigate = ability.can(to.meta.action || 'read', to.meta.resource, to.meta.params) // Casl router restriction
	}

	if (requiresLoggedIn) {
		// Logged in
		if (!user) {
			// Not logged in
			router.push({
				name: 'Login',
				query: { redirect: to.fullPath },
			})
		} else if (user) {
			if (to.name == 'AccessDenied') {
				next()
			} else if (!canNavigate) {
				router.push({
					name: 'AccessDenied',
				})
			} else {
				next()
			}
		}
	} else if (requiresNotLoggedIn) {
		console.log('requiresNotLoggedIn')
		// Not logged in
		if (!user) {
			next()
		} else if (user) {
			router.push({
				name: 'Companies',
			})
		}
	} else {
		next()
	}
})

export default router
